import { useState, useEffect } from "react";
import styled from "styled-components";
import Layout from "./components/Layout";
import { PrimaryButton, SignatureTypeButton, PromotionButton} from "./components/Buttons";
import Hero from "./components/Hero";
import SignatureRenderer from "./components/Renderer";
import Clipboard from "./components/Copy";

const SignatureWrapper = styled.div`
  position: relative;
  .signature {
    position: relative;
    z-index: 2;
    background: var(--iron);
    padding: 2rem;
    margin: 4rem 0 2rem 0;
  }
  .error-message {
    position: absolute;
    z-index: 1;
    height: 100%;
    inset: 0 auto auto 0;
    transform: translateY(0);
    transition: all 500ms ease-in-out;
    background: var(--bronze);
    padding: 0.75rem 1.25rem;
    p {
      font-family: var(--rift);
      font-weight: bold;
      color: var(--ink);
    }
    @media screen and (max-width: 999px) {
      width: 100%;
      text-align: center;
      p {
        font-size: 1rem;
      }
    }
  }
  .show-error-message {
    transform: translateY(-50px);
  }
`;

const InputSignature = styled.div`
  border: 1px solid var(--slate);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  input {
    color: var(--smoke);
    font-size: var(--font-medium);
    font-family: var(--rift);
    font-weight: bold;
    padding: 1.75rem 1.25rem;
    background: none;
    border: none;
    transition: all 300ms;
    &:focus {
      background: var(--tombstone);
      outline: none;
    }
  }
  input.full-name {
    border-right: 1px solid var(--slate);
    border-bottom: 1px solid var(--slate);
  }
  input.pronouns {
    border-bottom: 1px solid var(--slate);
  }
  input.title {
    border-right: 1px solid var(--slate);
  }
  ::placeholder {
    color: var(--smoke);
    opacity: 0.65;
  }
  .text-only-signature {
    font-family: var(--elza);
    font-weight: bold;
    height: 85px;
    &::placeholder {
      font-family: var(--rift);
      font-weight: bold;
    }
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    input.full-name {
      border-bottom: 1px solid var(--slate);
      border-right: none;
    }
    input.pronouns {
      border-bottom: 1px solid var(--slate);
    }
    input.title {
      border-right: none;
      border-bottom: 1px solid var(--slate);
    }
  }
`;

const OptionsRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap:0 1rem;
  padding: 20px 0 0 0;
  label {
    color: var(--smoke);
    font-family: var(--elza);
    font-size: var(--font-small);
    padding: 0 0 10px 0;
    display: block;
    line-height: 1.4;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 25px;
  }
`;

const PromotionRow = styled.div`
  .active{    
    height:80px !important;
    opacity: 1 !important;
    visibility: visible;
    @media screen and (max-width: 975px) {
      height:175px !important;
    }
    @media screen and (max-width: 768px) {
      height:250px !important;
    }
  }
  .hidden{
    height: 0 !important;
    opacity: 0 !important;
    visibility: hidden;    
  }
  .row{
    margin: 3.5rem 0 0 0;
    border: 1px solid var(--slate);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    position: relative;
    transition: all 300ms;
    input {
      color: var(--smoke);
      font-size: var(--font-base);
      font-family: var(--elza);
      padding: 1.75rem 1.25rem;
      background: none;
      border: none;
      transition: all 300ms;
    &:focus {
      background: var(--tombstone);
      outline: none;
    }
    &::placeholder {
      font-size: var(--font-medium);
      font-family: var(--rift);
      font-weight: bold;
    }
  }
  input.promotional-description {
    grid-column: span 2;
  }
  input.promotional-description,
  input.promotional-cta {
    border-right: 1px solid var(--slate);
  }
  label {
    position: absolute;
    inset: -37px auto auto 0;
    padding: 0.5rem 0;
    font-family: var(--rift);
    font-weight: bold;
    font-size: var(--font-base);
    color: var(--smoke);
  }
  @media screen and (max-width: 975px) {
    input.promotional-description {
      grid-column: span 4;
      border-bottom: 1px solid var(--slate);
      border-right: none;
    }
    input.promotional-cta,
    input.promotional-url {
      grid-column: span 2;
    }
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    input.promotional-description {
      border-bottom: 1px solid var(--slate);
      border-right: none;
      grid-column: span 1;
    }
    input.promotional-cta {
      border-right: none;
      border-bottom: 1px solid var(--slate);
    }
  }
  }
`;

const GeneratedSignature = styled.div`
  #signature-container {
    background: var(--smoke);
    transition: all 300ms;
  }
  .top-row {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 0.25rem;
    margin: 0 0 3px 0;
  }
  .name-pronouns {
    display: flex;
    gap: 0.25rem;
    padding: 0;
    line-height: 1;
  }
  .middle-row {
    margin: 0 0 6px 0;
  }
  .middle-row-text-only {
    margin: 0 0 10px 0;
    line-height: 1;
  }
  p.success-message {
    color: var(--smoke);
    font-family: var(--rift);
    font-size: var(--font-large);
    font-weight: bold;
    line-height: 1.15;
  }
  // Do not use CSS variables on classes below as they won't work properly when the signature is copy/pasted into email clients.
  p.full-name {
    font-family: "Elza", sans-serif; // No CSS variable
    color: black; // No CSS variable
    font-size: 14px;
    font-weight: bold;
  }
  p.pronouns {
    font-family: "Elza", sans-serif; // No CSS variable
    color: #727272; // No CSS variable
    font-size: 14px;
  }
  p.title {
    font-family: "Elza", sans-serif; // No CSS variable
    color: #727272; // No CSS variable
    font-size: 11px;
    font-weight: bold;
    margin: 2px 0 0 0;
  }
  p.phone-number {
    font-family: "Elza", sans-serif; // No CSS variable
    color: black; // No CSS variable
    font-size: 10px;
  }
  a.external-link {
    font-family: "Elza", sans-serif; // No CSS variable
    color: black; // No CSS variable
    font-size: 10px;
    text-decoration: none;
  }
  .promotional-row {
    max-width: 220px;
  }
  .promotional-content {
    font-family: "Elza", sans-serif; // No CSS variable
    color: black; // No CSS variable
    border: 1px solid #9c794f; // No CSS variable
    line-height: 1.2;
    font-size: 10px;
    text-decoration: none;
    padding: 7px 10px;
    margin: 0 0 7px 0;
    display: inline-block;
  }
  a.promotional-cta {
    color: black; // No CSS variable
    font-family: "Elza", sans-serif; // No CSS variable
    text-decoration: underline;
  }
`;

export default function App() {
  const [generateEmail, setGenerateEmail] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);
  const [emailFields, setEmailFields] = useState({
    full_name: "",
    pronouns: "",
    title: "",
    phone: "",
    promotional_description: "",
    promotional_cta: "",
    promotional_url: "",
  });
  const [textOnlySignature, setTextOnlySignature] = useState(false);
  const [inputFieldType, setInputFieldType] = useState({
    full_name: "",
    pronouns: "",
    title: "",
    phone_number: "",
  });
  const [enablePromotionArea, setEnablePromotionArea] = useState(false);
  const [promotionRowClass, setPromotionRowClass] = useState("");

  const handleToggle = (toggleType) => {
    toggleType((prev) => !prev);    
  }

  useEffect(() => {
    if(enablePromotionArea){
      setPromotionRowClass("active");
    }else{
      setPromotionRowClass("hidden");
    }
  },[enablePromotionArea])

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEmailFields((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const createSignature = () => {
    const validateFields = () => {
      const requiredFields = ["full_name", "title"];
      for (let field of requiredFields) {
        if (!emailFields[field] || emailFields[field].trim() === "") {
          return false;
        }
      }
      return true;
    };
    if (validateFields()) {
      setGenerateEmail(!generateEmail);
    } else {
      setErrorMessage(true);
      setTimeout(() => {
        setErrorMessage(false);
      }, 3000);
    }
  };

  const createAgain = () => {
    setGenerateEmail(!generateEmail);
    const resetFields = Object.keys(emailFields).reduce((acc, key) => {
      acc[key] = "";
      return acc;
    }, {});
    setEmailFields(resetFields);
  };

  return (
    <Layout>
      <Hero />
      <SignatureWrapper>
        <div className="signature">
          <div style={{ display: generateEmail ? "block" : "none" }}>
            <InputSignature>
              <input
                className={`full-name ${inputFieldType.full_name}`}
                placeholder="Full Name"
                name="full_name"
                onChange={handleInputChange}
                type="text"
                value={emailFields.full_name}
              ></input>
              <input
                className={`pronouns ${inputFieldType.pronouns}`}
                placeholder="Your Pronouns (They/Them)"
                name="pronouns"
                onChange={handleInputChange}
                type="text"
                value={emailFields.pronouns}
              ></input>
              <input
                className={`title ${inputFieldType.title}`}
                placeholder="Your Title/Role"
                name="title"
                onChange={handleInputChange}
                type="text"
                value={emailFields.title}
              ></input>
              <input
                className={inputFieldType.phone_number}
                placeholder="Phone No."
                name="phone"
                type="telephone"
                onChange={handleInputChange}
                value={emailFields.phone}
              ></input>
            </InputSignature>
            <OptionsRow>
              <div>
                <label>
                  Toggle to create standardized text signature (higher
                  deliverability)
                </label>
                <SignatureTypeButton                  
                  handleToggle={() => handleToggle(setTextOnlySignature)}
                  signatureType={textOnlySignature}
                  inputFieldType={setInputFieldType}
                />
              </div>
              <div>
                <label>
                  Toggle to add optional promotion area to signature
                </label>
                <PromotionButton                  
                  handleToggle={() => handleToggle(setEnablePromotionArea)}
                  toggleEnabled={enablePromotionArea}
                />
              </div>
            </OptionsRow>            
            <PromotionRow>
              <div className={`row ${promotionRowClass}`}>
                <label>Promotion Area</label>
                <input
                  className="promotional-description"
                  placeholder="Description"
                  name="promotional_description"
                  type="text"
                  onChange={handleInputChange}
                  value={emailFields.promotional_description}
                ></input>
                <input
                  className="promotional-cta"
                  placeholder="Link Title"
                  name="promotional_cta"
                  type="text"
                  onChange={handleInputChange}
                  value={emailFields.promotional_cta}
                ></input>
                <input
                  className="promotional-url"
                  placeholder="Link URL"
                  name="promotional_url"
                  type="text"
                  onChange={handleInputChange}
                  value={emailFields.promotional_url}
                ></input>
              </div>
            </PromotionRow>
          </div>
          <GeneratedSignature
            style={{
              display: !generateEmail ? "block" : "none",
            }}
          >
            <p className="success-message">
              You're done! Copy and paste into your email client.
            </p>
            <Clipboard
              data={{
                default_text: "Copy Signature",
                copied_text: "Copied!",
                selection: "signature-container",
              }}
            />
            <div id="signature-container" style={{ padding: "30px" }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: `
                      <html>
                        <head>
                          <style>
                          #signature-container{
                            background:#fff !important;
                          }
                            @media (prefers-color-scheme: dark) { 
                              #signature-container{ 
                                background: #fff !important;
                                color: #000 !important;
                              }
                            }
                            #vssl-site{
                              color: black !important;
                            }
                          </style>
                        </head>
                      </html>
                    `,
                }}
              />
              <div className="top-row">
                <img
                  src="/images/vssl.png"
                  alt="VSSL flag logomark"
                  width={35}
                  height={26}
                />

                <div>
                  <div
                    className="name-pronouns"
                    style={{ lineHeight: "1 !important" }}
                  >
                    {textOnlySignature ? (
                      <>
                        <p
                          className="full-name"
                          style={{
                            fontFamily: "'Elza', sans-serif !important",
                            color: "black !important",
                            fontSize: "14px !important",
                            fontWeight: "bold !important",
                          }}
                        >
                          {emailFields.full_name}
                        </p>
                        <p
                          className="pronouns"
                          style={{
                            fontFamily: "'Elza', sans-serif !important",
                            color: "#727272 !important",
                            fontSize: "14px !important",
                          }}
                        >
                          {emailFields.pronouns}
                        </p>
                      </>
                    ) : (
                      <>
                        <SignatureRenderer
                          data={{
                            color: "ink",
                            size: "17px",
                            text: emailFields.full_name,
                            brackets: false,
                            font_size: "regular",
                          }}
                        />
                        <SignatureRenderer
                          data={{
                            color: "iron",
                            size: "17px",
                            text: emailFields.pronouns,
                            brackets: true,
                            font_size: "regular",
                          }}
                        />
                      </>
                    )}
                  </div>
                  {textOnlySignature ? (
                    <p
                      className="title"
                      style={{
                        fontFamily: "'Elza', sans-serif !important",
                        color: "#727272 !important",
                        fontSize: "11px !important",
                        fontWeight: "bold !important",
                        margin: "1px 0 0 0 !important",
                      }}
                    >
                      {emailFields.title}
                    </p>
                  ) : (
                    <SignatureRenderer
                      data={{
                        color: "iron",
                        size: "13px",
                        text: emailFields.title,
                        brackets: false,
                        font_size: "small",
                      }}
                    />
                  )}
                </div>
              </div>
              {textOnlySignature ? (
                <div
                  className="middle-row-text-only"
                  style={{
                    lineHeight: "1 !important",
                    margin: "0 0 10px 0 !important",
                  }}
                >
                  <p
                    className="phone-number"
                    style={{
                      display:
                        emailFields.phone.length > 0 ? "inline-block" : "none",
                      fontFamily: "'Elza', sans-serif !important",
                      color: "black !important",
                    }}
                  >
                    {emailFields.phone}
                    {"\u00A0".repeat(2)}|{"\u00A0".repeat(2)}
                  </p>
                  <a
                    href="https://vsslagency.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="external-link"
                    id="vssl-site"
                    style={{
                      color: "black !important",
                      textDecoration: "none !important",
                      fontFamily: "'Elza', sans-serif !important",
                    }}
                  >
                    vsslagency.com
                  </a>
                </div>
              ) : (
                <div className="middle-row">
                  <p
                    className="phone-number"
                    style={{
                      display:
                        emailFields.phone.length > 0 ? "inline-block" : "none",
                      fontFamily: "'Elza', sans-serif !important",
                      color: "black !important",
                    }}
                  >
                    {emailFields.phone}
                    {"\u00A0".repeat(2)}|{"\u00A0".repeat(2)}
                  </p>
                  <a
                    href="https://vsslagency.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="external-link"
                    id="vssl-site"
                    style={{
                      color: "black !important",
                      textDecoration: "none !important",
                      fontFamily: "'Elza', sans-serif !important",
                    }}
                  >
                    vsslagency.com
                  </a>
                </div>
              )}
              {(emailFields.promotional_description || emailFields.promotional_url) && enablePromotionArea && (
                <div
                  className="promotional-row"
                  style={{ maxWidth: "250px !important" }}
                >
                  <p
                    className="promotional-content"
                    style={{
                      display: "inline-block !important",
                      fontFamily: "'Elza', sans-serif !important",
                      fontSize: "10px !important",
                      lineHeight: "1.2 !important",
                      color: "black !important",
                      border: "1px solid #9c794f !important",
                      padding: "7px 10px !important",
                      margin: "0 0 7px 0 !important",
                    }}
                  >
                    {emailFields.promotional_description}
                    {emailFields.promotional_url && (
                      <>
                        {" "}
                        <a
                          className="promotional-cta"
                          href={emailFields.promotional_url}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            color: "black !important",
                            textDecoration: "underline !important",
                            fontFamily: "'Elza', sans-serif !important",
                          }}
                        >
                          {emailFields.promotional_cta}
                        </a>
                      </>
                    )}
                  </p>
                </div>
              )}
              <img
                src="/images/one-percent.png"
                alt="1% For The Planet"
                width={48}
                height={20}
              />
            </div>
          </GeneratedSignature>
          <PrimaryButton
            data={{
              title: `${generateEmail ? "Generate" : "Generate Another"}`,
              action: generateEmail ? createSignature : createAgain,
            }}
          />
        </div>
        <div
          style={{
            display:
              emailFields.full_name.length > 0 && emailFields.title.length > 0
                ? "none"
                : "block",
          }}
          className={`error-message ${
            errorMessage ? "show-error-message" : ""
          }`}
        >
          <p>
            Bogus!{" "}
            {emailFields.full_name.length === 0 && emailFields.title
              ? "Full Name"
              : ""}{" "}
            {emailFields.title.length === 0 && emailFields.full_name
              ? "Title/Role"
              : ""}{" "}
            {emailFields.full_name.length === 0 &&
            emailFields.title.length === 0
              ? `Full Name and Title/Role`
              : ""}{" "}
            missing.
          </p>
        </div>
      </SignatureWrapper>
    </Layout>
  );
}
